/* styles/footerBanner.css */

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Empêche le débordement horizontal */
}

body {
  display: flex;
  flex-direction: column; /* La page est traitée comme une colonne */
}

.footer-banner {
  margin-top: auto; /* Pousse le footer vers le bas automatiquement */
  width: 100%;
  background-color: #f8f9fa; /* Couleur de fond du footer */
  padding: 20px; /* Padding pour donner de l'espace autour du contenu */
}

.footer-banner ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center; /* Centrer les liens à l'intérieur du footer */
  margin: 0;
}

.footer-banner li {
  padding: 0 50px;
}

.footer-banner a {
  text-decoration: none;
  color: #333; /* Couleur des liens */
  font-weight: none;
}
