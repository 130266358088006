body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Supposons que votre footer ait une hauteur de 50px */
.main-content {
  min-height: calc(100vh - 0px); /* 100vh représente la hauteur totale de la fenêtre du navigateur */
  display: flex;
  flex-direction: column;
}

.category-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-filter-group {
  margin-top: 50px;
 
}

.category-filter-group label {
  font-weight: bold;
}

.category-filter-group input[type="checkbox"] {
  margin-right: 10px;
}

.category-filter-group .main-category {
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 5px;
}


.category-filter-group .subcategory {
  margin-left: 20px;
}

.split {
  display: flex;
  height: 100vh; /* Ajustez la hauteur selon vos besoins */
}

.event-template .link a {
  color: #1a73e8; /* Couleur du lien */
  text-decoration: underline; /* Souligner le lien */
  /* Autres styles CSS si nécessaire */
}

.e-quick-popup-wrapper {
  width: 500px !important; /* Augmentez selon vos besoins */
}


.tooltip-content {
  max-width: 500px;
}

.tooltip-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.tooltip-text {
  text-align: center;
}

.tooltip-text .subject {
  margin-top: 10px;
  font-size: 16px;
}

.tooltip-text .time {
  margin-top: 5px;
  font-size: 13px;
}

